require("fslightbox");

const galeryScrollReveal = () => {
  ScrollReveal().reveal(".block-galerie-image", {delay: 600, duration: 1000, easing: "ease-in-out"}); // eslint-disable-line
};

const galerySwiper = () => {
  const blocks = document.querySelectorAll(".block-galerie-image .swiper");

  if (blocks.length === 0) {
    return;
  }

  [...blocks].forEach((item) => {
    const config = {
      loop: true,
      lazy: true,
      breakpoints: {
        320: {
          slidesPerView: 1.5,
        },
        1024: {
          slidesPerView: 2.5,
          spaceBetween: 130,
        },
      },
      spaceBetween: 38,
      centeredSlides: true,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    const swiper = new Swiper(item, config);// eslint-disable-line

    // handle lightbox if it's a image slider
    if (item.dataset.choice === "image") {
      refreshFsLightbox();// eslint-disable-line
    }
  });
};

document.addEventListener("DOMContentLoaded", () => {
  galeryScrollReveal();
  galerySwiper();
});
